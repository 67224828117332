<template>
<div class="support">

    <div class="description-sec">
        <h1 class="header">
            مزایای ترید و معامله با ما
        </h1>
        <p class="text">تسا هدش یلاتیجید یاهزرا ریاس و نیوک تیب شورف و دیرخ رد ﻦﻣﺍ و توافتم یا هبرجت داجیا ثعاب درفبرصحنم یایازم و تاناکما
        </p>
        <div class="social-medias-icons">
            <div class="icon-container" v-for="(icon,index) in icons" :key="index">
                <span class="slide-title">
                    {{icon.title}}
                </span>
                <inline-svg v-if="state.lightTheme" class="icon" :src="require('../../../assets/public/'+icon.img+'.svg')" />
                <inline-svg v-if="!state.lightTheme" class="icon" :src="require('../../../assets/public/'+icon.img+'Dark'+'.svg')" />
            </div>
        </div>

    </div>
    <!-- <div class="support-bg">
        <img src="../../../assets/public/homepage-support-bg.png"/>

      </div> -->

</div>
</template>

<script>
export default {
    name: 'Support',
    props: {},
    data() {
        return {
            icons: [{
                    title: 'امنیت بالا معاملات',
                    img: 'safety'
                },
                {
                    title: 'احراز هویت سریع',
                    img: 'authorization'
                },
                {
                    title: 'پشتیبانی سریع و ۲۴ ساعته',
                    img: 'support'
                },
                {
                    title: 'معاملات سریع و حرفه ای',
                    img: 'trade'
                },
            ]
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.header {
    font-size: clamp(20px, 1.7vw, 24px);
    font-weight: bold;
    color: var(--navbar-btn-text-hover);
    text-align: center;
}

.sub-header {
    font-size: clamp(16px, 1.7vw, 18px);
    color: var(--publicpage-support-subheader);
}

.text {
    font-size: clamp(13px, 1.3vw, 14px);
    color: var(--publicpage-support-text);
    text-align: center;
}

.support {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 20px;
    direction: ltr;
    width: 100%;
    flex-grow: 1;
    padding: 0 15px;
}

.icon {
    stroke: var(--publicpage-support-icon);
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: var(--background);
    border-radius: 50px;
    width: 278px;
    height: 310px;
    box-shadow: var(--box-shadow);
}

.social-medias-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    column-gap: 30px;
    row-gap: 20px;
}

.description-sec {

    max-width: 1400px;
    flex-grow: 1;
    direction: rtl;
}

.support-bg {
    display: flex;
    flex: 4.5;
    flex-basis: 600px;
    position: relative;
    max-width: 739px;

}

.support-bg>img {
    width: 100%;
}

.slide-title {
    color: var(--slide-title);
}

@media only screen and (max-width:500px) {
    .icon {
        max-width: 150px !important;
    }
     .icon-container {
        width: 223px !important;
    height: 222px !important;

  }
}
</style>
