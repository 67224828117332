<template>
<div class="easy-container">
    <div class="text-container">
        <p class="easy-title">
            در سایت (اسم سایت) ثبت نام کنید و به راحتی معامله کنید
        </p>
        <p class="easy-text">
            مراحل ثبت نام در سایت راحت و کوتاه است فقط کافی روی دکمه زیر کلید کنید و مراحل را به ترتیب انجام دهید
        </p>
        <button class="normal-btn" @click="$router.push('/register')">
            شروع ایجاد حساب کاربری
        </button>
    </div>
    <div>
        <img v-if="state.lightTheme" src="@/assets/public/EasyLight.svg" alt="">
        <img v-if="!state.lightTheme" src="@/assets/public/EasyDark.svg" alt="">
    </div>
</div>
</template>

<script>
export default {
    name: 'EasyTrade',
    data() {
        return {

        }
    },
}
</script>

<style lang="scss" scoped>
.easy-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 90px;
    align-items: center;
    row-gap: 50px;
    height: 100%;
    padding: 0 15px;
}

.normal-btn {
    background: var(--normal-btn);
    color: #FFFFFF;
    width: 173px;
    padding: 10px 0;
    border-radius: 50px;
}

.easy-text {
    color: var(--navbar-btn-text);
}

.easy-title {
    color: var(--authcard-title);
}
img {
    max-width: 100%;
}
@media only screen and (max-width:500px) {
    .easy-title {
        text-align: center;
    }
    .easy-text {
        text-align: center;
    }
    .normal-btn {
        margin: 0 auto;
    }
    .text-container {
        display: flex;
    flex-direction: column;
    }
}
</style>
