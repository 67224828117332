<template>
<div class=" table-section">
    <div class="sell-table">
        <p class="depth-title negative-change">
            سفارشات فروش
        </p>
        <div class=" grid-row ">
            <div class="light-font">قیمت واحد({{$coins[tradeTo].persianName}})</div>
            <div class="light-font">مقدار({{$coins[tradeFrom].code}})</div>
            <div class="light-font resp">قیمت کل({{$coins[tradeTo].persianName}})</div>
        </div>
        <div class=" grid-row " v-for="(order,index) in tableContentsSell" :key="index" @click="setSellAmount(order.volume)">
            <div class="light-font negative-change">{{$toLocal(order.unitPrice,0)}}</div>
            <div class="light-font">{{$toLocal(order.volume,$decimal[tradeFrom])}}</div>
            <div class="light-font resp">{{$toLocal(order.wholePrice,0)}}</div>
            <div class="grad-bg red-bg" :style="{width:order.executedPercent+'%'}" />
        </div>

    </div>
     <div class="price" :class="{'positive-change':state.shopInfo.change24 > 0 , 'negative-change':state.shopInfo.change24 < 0}">
        {{$toLocal(state.shopInfo.lastPrice,precision) || 0}}
    </div>
    <div class="buy-table">
        <p  class="depth-title positive-change">
            سفارشات خرید
        </p>
        <div class=" grid-row ">
            <div class="light-font">قیمت واحد({{$coins[tradeTo].persianName}})</div>
            <div class="light-font">مقدار({{$coins[tradeFrom].code}})</div>
            <div class="light-font resp">قیمت کل({{$coins[tradeTo].persianName}})</div>
        </div>
        <div class=" grid-row " v-for="(order,index) in tableContentsBuy" :key="index"  @click="setBuyAmount(order.volume)">
            <div class="light-font positive-change">{{$toLocal(order.unitPrice,0)}}</div>
            <div class="light-font">{{$toLocal(order.volume,$decimal[tradeFrom])}}</div>
            <div class="light-font resp">{{$toLocal(order.wholePrice,0)}}</div>
            <div class="grad-bg green-bg" :style="{width:order.executedPercent+'%'}" />
        </div>

    </div>

</div>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
} from "@/library/reuseableFunction";
export default {
    name: 'OrderBookTable',
    props: ['tableContentsBuy', 'tableContentsSell'],
    computed: {
        tradeTo,
        tradeFrom
    },
    data() {
        return {
            choice: this.default || '',
            dropOpen: false,
        }
    },
    methods: {
        // filterOrder(tradeType) {
        //     return this.orders.filter(order => {
        //         if (this.$coinUnit[order.coinName] == this.coin.symbol &&
        //             tradeType == order.tradeType)
        //             return order
        //     })
        // }
        setSellAmount(e) {
            this.state.sellAmount = e
        },
        setBuyAmount(e) {
            this.state.buyAmount = e
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.dropdown {
    padding: 0px 16px;
    border-radius: 20px;

}

.items {
    height: 0px;
    transition: height 0.8s;
    overflow: hidden;
}

.field {
    height: 39px;
    font-size: clamp(13px, 1.4vw, 14px);
}

.icon {
    stroke: var(--dropdown-icon)
}

.grad-bg {
    height: 20px;
    position: absolute;
    z-index: 0;
    left: 0px;
    opacity: 0.2;
    border-radius: 20px;
}

.red-bg {
    background: rgba(245, 43, 43, 1);;
}

.green-bg {
    background: rgba(11, 200, 16, 1);
}
.depth-title {
    text-align: center;
    font-size: clamp(11px, 1.3vw, 13px);
    margin: 0;
    text-align: center;
}


.grid-row {
    display: flex;
    position: relative;
    height: 20px;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    color: var(--P2P-table-row-color);
    font-size: clamp(12px, 1.3vw, 13px);
    font-family: 'YekanLight';
    margin-top: 4px;
    justify-content: space-between;
    cursor: pointer;

}
.sell-table > .grid-row:hover {
    background: rgba(245, 43, 43, 0.1);
}
.buy-table > .grid-row:hover {
        background: rgba(11, 200, 16 , 0.1);
}

.grid-row>div:first-child {
    text-align: right;
    z-index: 1;
}

.grid-row>div:nth-child(3) {
    text-align: left;
    z-index: 1;

}

.grid-row>div:nth-child(2) {
    text-align: center;
    z-index: 1;

}

.table-section {
    background: var(--dashboard-section-bg);
    padding: 16px;
    border-radius: 15px;
    flex-direction: column;
    display: flex;
    flex-grow: 2;
    flex-basis: 250px;
    min-width: 230px;

}

.table-section > div {
    // flex: 1;
}
.price {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}
@media(max-width:1000px) {
   .table-section {
        min-width: 147px
   } 
    .resp {
        display: none;
    }
}
</style>
