<template>
<div class="carousel-container">
  <p class="carousel-title">
    بنر خبرهای سایت
  </p>
    <vueper-slides
    
    :breakpoints="breakpoints"
    class="no-shadow carousel"
    :visible-slides="4"
    :arrows="false"
    :gap="1"
    :bullets="false"
    :rtl="true"
    :arrowsOutside="false"     
      :slide-ratio="1 / 4"
      
      :infinite="true"

    >
    <vueper-slide  v-for="(crypto,index) in cryptos" :key="index" :title="index.toString()">
      <template #content>
      <div class="vueperslide__content-custom" >
       
        <img  class="bg" src='../../../assets/public/eventBg.png'/>

       

      </div>
    </template>
    </vueper-slide>
    <template #arrow-left>
        <div class="arrow">
            <img src="../../../assets/public/carousel-arrow.svg"/>
        </div>
    </template> 
    <template #arrow-right>
        <div class="arrow reverse-arrow">
            <img src="../../../assets/public/carousel-arrow.svg"/>
        </div>
    </template>
    </vueper-slides>

</div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import variables from '../../../library/variables.json'
export default {
  name: 'CryptoCarousel',
  props: {
   
  },
  data(){
    return{
          cryptos:variables.cryptos,
          breakpoints: {
                 
               
                980: {
                  visibleSlides: 2
                },
              
                
                 550: {
                  visibleSlides: 1,                
                },
                // The order you list breakpoints does not matter, Vueper Slides will sort them for you.
               
              },

    }
  },
   components: { VueperSlides, VueperSlide },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .vueperslide__content-custom{
  // background: url('../../../assets/public/eventBg.png');
  background-size:contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  display: flex;
    align-items: center;
  
}
img {
  max-width: 90%;
  margin: 0 auto;
}
.carousel-container {
  margin-bottom: 90px;
}
</style>
<style  lang="scss">

.reverse-arrow{
    transform: rotatez(180deg);
}
.arrow{
    height: 32px;
    width: 32px;
    border-radius: 50%;
   
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--events-carousel-arrow);
}

.bg{
  
  height: auto;
}

.carousel{
    // margin-top: 50px;
    width: 100%;
   
    
}
.vueperslides__track {
  
}
.vueperslide {
    display: flex !important;
    justify-content: center;
}
.vueperslide__content-wrapper {
  height: 140px !important;
}
.vueperslides__track {
    height: 190px !important;
}
.carousel-container{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    background: var(--background-slider);
}
.vueperslides {
    height: 180px;
    
}
.carousel-title {
  text-align: center;
  color: var(--navbar-link);
  font-size: 24px;
  margin-bottom: 5px;
   margin-top: 10px;
}
.vueperslides__inner {
  height: 100%;
}
.vueperslides__parallax-wrapper {
  padding-bottom: 0 !important;
}
.vueperslides__parallax-wrapper {
    position: relative;
    overflow: unset;
}
</style>