<template>
<div class="fee" >
    <div class="title">
                کارمزدها
            </div>
    <div class="fee-baner" >
        <div>
            
            <p class="text">
                ﮐﺘﺎﺑﻬﺎی زﯾﺎدی در ﺷﺼﺖ و ﺳﻪ درﺻﺪ ﮔﺬﺷﺘﻪ ﺣﺎل و آﯾﻨﺪه، ﺷﻨﺎﺧﺖ ﻓﺮاوان ﺟﺎﻣﻌﻪ و ﻣﺘﺨﺼﺼﺎن راﺷﺮاﯾﻂ ﻓﻌﻠﯽ ﺗﮑﻨﻮﻟﻮژی ﻣﻮرد ﻧﯿﺎز، و ﮐﺎرﺑﺮدﻫﺎی ﻣﺘﻨﻮع ﺑﺎ ﻫﺪف ﺑﻬﺒﻮد اﺑﺰارﻫﺎی ﮐﺎرﺑﺮدی ﻣﯽ ﺑﺎﺷﺪ،ﮔﺮاﻓﮏﯿ اﺳﺖ، ﭼﺎﮕﭘﺮﻫﺎ و ﻣﺘﻮن ﺑﻠﮑﻪ روزﻧﺎﻣﻪ و ﻣﺠﻠﻪ در ﺳﺘﻮن و ﺳﻄﺮآﻧﭽﻨﺎن ﮐﻪ ﻟﺎزم اﺳﺖ، و ﺑﺮایﻟﻮرم اﭙﯾﺴﻮم ﻣﺘﻦ ﺳﺎﺧﺘﯽﮕ ﺑﺎ ﺗﻮﻟﯿﺪ ﺳﺎدﯽﮔ ﻧﺎﻣﻔﻬﻮم از ﺻﻨﻌﺖ ﭼﺎپ، و ﺑﺎ اﺳﺘﻔﺎده از ﻃﺮاﺣﺎن
            </p>
            <p class="items">
                ﮐﺎرﻣﺰد ﺧﺮﯾﺪار ارزدﯾﺠﯿﺘﺎل ﺑﻪ ﺻﻮرت ارزدﯾﺠﯿﺘﺎل ﮐﺴﺮ و ﮐﺎرﻣﺰد ﻓﺮوﺷﻨﺪه ارزدﯾﺠﯿﺘﺎل از ﻣﺒﻠﻎ درﯾﺎﻓﺘﯽ ﺑﻪ ﺗﻮﻣﺎن ﮐﺴﺮ ﻣﯽ ﺷﻮد.
            </p>
            <p class="items">
                کارمزد از ﻫﺮ دو ﺳﻤﺖ ﻣﻌﺎﻣﻠﻪ ﮐﺴﺮ ﻣﯽ ﺷﻮد.
            </p>
            <p class="items">
                در ﻫﻨﮕﺎم ﺛﺒﺖ ﻣﻌﺎﻣﻠﺎت در واﻟﮑﺲ، ﮐﺎرﻣﺰد ﺑﻪ ﺷﻤﺎ ﻧﻤﺎﯾﺶ داده ﻣﯽ ﺷﻮد.
            </p>
        </div>
        <img v-if="state.lightTheme" src="@/assets/public/fee.svg" alt="">
        <img v-else src="@/assets/public/feeDark.svg" alt="">
    </div>
    <div class="fee-tables">
        <div>
            <p class="subtitle">
                سطوح کاربری و کارمزد معاملات
            </p>
            <p class="items">
                با توجه به حجم معاملات سه ماه اخیر کاربر، کارمزد معاملات طبق جدول زیر به صورت پلکانی محاسبه می گردد:
            </p>
            <div class="table-container">
                <table class="multi-color">
                    <tr class="tr-head">
                        <td class="items">
                            سطح کاربری
                        </td>
                        <td class="items">
                            میزان معامله در ۳ ماه اخیر
                        </td>
                        <td class="items">
                            کارمزد
                        </td>
                    </tr>
                    <tr class="tr-body" v-for="(level,index) in $accountLevel.filter(e => e.userAccountLevel !== 'SUPERVISOR')" :key="index">
                        <td>
                            سطح کاربری {{$levels[level.userAccountLevel]}}
                        </td>
                        <td class="number-font" v-if="index === 0">
                            کمتر از {{tradeAmount($accountLevel[index].tradeAmount)}} میلیون
                        </td>
                        <td class="number-font" v-else-if="index === ($accountLevel.length-2)">بیشتر از
                            {{tradeAmount($accountLevel[index-1].tradeAmount)}}
                            میلیون
                        </td>
                        <td v-else>
                            از
                            {{tradeAmount($accountLevel[index-1].tradeAmount)}}
                            تا
                            {{tradeAmount($accountLevel[index].tradeAmount)}}
                            میلیون
                        </td>
                        <td class="number-font">
                            % {{level.wagePercent}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div>
            <p class="subtitle">
                کارمزد برداشت ارزها
            </p>
            <p class="items">
                کارمزد برداشت دارایی‌های دیجیتال به نوع ارز بستگی دارد. این هزینه در واقع به دلیل کارمزدهای تراکنش شبکه کسر می‌شود و بامبو در آن نقشی ندارد. در حال حاضر این هزینه‌ها به شرح زیر است:
            </p>
            <div class="table-container">
                <table class="multi-color">
                    <tr class="tr-head">
                        <td  class="items">
                            نوع ارز
                        </td>
                        <td  class="items">
                            حداقل میزان قابل برداشت
                        </td>
                        <td  class="items">
                            کارمزد
                        </td>
                    </tr>
                    <tr class="tr-body" v-for="(coin , index) in coins" :key="index">
                        <td>
                            {{$coins[coin.coin].persianName}}
                        </td>
                        <td class="min-td">
                            <span class="number-font"> {{coin.tokenTypeDetails[0].minWithdraw}} </span>&nbsp;
                            <span> {{$coins[coin.coin].code}}</span>

                        </td>
                        <td class="fee-td">
                            <span> {{coin.tokenTypeDetails[0].withdrawalFee}} </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="fee-describtion">
        <p class="subtitle">
            کارمزد برداشت ریالی
        </p>
        <p >
            برداشت‌های ریالی برای تمامی حساب‌ها، در قالب انتقال پایا و یک بار در روز خواهد بود. بدیهی است مدت زمان انجام تسویه ریالی، بسته به زمان درخواست برداشت، در روزهای غیر تعطیل می‌تواند حداکثر تا ۲۴ ساعت به طول انجامد.
        </p>
    </div>
</div>
</template>

<script>
export default {
    name: 'fee',
    data() {
        return {
            coins: []
        }
    },
    computed: {
        tradeAmount() {
            return e => e ? e.toString().slice(0, -6) : 0
        }
    },
    methods: {
        async getData() {
            const res = await this.$axios.get('/coins/details?p2pSupported=true')
            this.coins = res.content
        }
    },
    mounted() {
        this.$getFees()
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.fee {
    width: 100%;
    max-width: 1400px;
    color: var(--banner-middle-text);
    box-sizing: border-box;
    padding: 0 20px;
}

.fee-baner {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    column-gap: 10px;
    row-gap: 20px;
    justify-content: space-between;
    //  min-height: 550px;

    div {
        max-width: 700px;
    }
}
table {
    width: 100%;
}
table tr {
    height: 40px;
    font-size: clamp(12px , 1vw , 14px);
    color: var(--banner-middle-text);
}
table tr td:first-child {
    padding-right: 15px;
}
table tr td:last-child {
    padding-left: 15px;
}
.fee-tables {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
        div {
            max-width: 500px;
        }
}
.table-container {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}
.title {
    font-size: clamp(25px,4vw,48px);
     color: var(--public-title);
}
.subtitle {
    font-size: 24px;
}
.items {
    opacity: 0.7;
    font-weight: 300;
    font-size: 14px;
}
.fee-describtion {
    margin:50px 0;
}
img {
    max-width: 100%;
    width: 400px;
}
</style>
