<template>
<div class="right-side-tables">
    <div class=" table-section">
        <table class="right-table">
            <tr class="table-header">
                <td>رمزارز</td>
                <td>قیمت({{$coinLabel[tradeTo]}})</td>
                <td>تغییرات</td>
            </tr>
            <tbody>
                <tr v-for="(crypto,index) in cryptos" :key="index" class="table-row">
                    <td class="light-font">{{crypto.relatedCoin}}</td>
                    <td>{{$toLocal(crypto.lastPrice,$decimal[tradeTo])}}</td>
                    <td :class="{'light-font':true,red:crypto.last24HourChange<0,green:crypto.last24HourChange>=0,}" dir='ltr'>
                        <span v-if="crypto.last24HourChange>=0">+</span>
                        <span v-if="crypto.last24HourChange<0">-</span>
                        {{$toLocal(Math.abs(crypto.last24HourChange),2)}}%
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="right-table table-section">
        <div class="value">آخرین معاملات</div>
        <table class="right-table">
            <tr class="table-header">
                <td>مقدار({{$coins[tradeFrom].code}})</td>
                <td>قیمت واحد({{$coinLabel[tradeTo]}})</td>
                <td>زمان</td>
            </tr>
            <tbody>
                <tr v-for="(trade,index) in lastTrades.slice(0,9)" :key="index" class="table-row">
                    <td :class="{'light-font':true,red:trade.orderType.includes('SELL'),
                            green:trade.orderType.includes('BUY')}">{{$toLocal(trade.amount,$decimal[tradeFrom])}}</td>
                    <td>{{$toLocal(trade.unitPrice,0)}}</td>
                    <td class="light-font">{{$G2J(trade.createdAtDateTime)}}</td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'HelloWorld',
    props: [ 'cryptos'],
    data() {
        return {
            lastTrades: []
        }
    },
    computed: {
        tradeTo,
        tradeFrom
    },
    methods: {
        async getLastTrades() {
            const res = await this.$axios.get('/trades/last-trades?type=P2P', {
                params: {
                    marketType: this.tradeFrom + '_' + this.tradeTo,
                    size: 18
                }
            })
            this.lastTrades = res.content
            this.loading = false
        },
    },
    async mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getLastTrades, 5000, 'realPrice' + (this.tradeFrom))
            this.loop.start()
        } else {
            this.getLastTrades()

        }

    },
    beforeDestroy() {
        this.loop.stop()
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.dropdown {
    padding: 0px 16px;
    border-radius: 20px;

}

.items {
    height: 0px;
    transition: height 0.8s;
    overflow: hidden;
}

.field {
    height: 39px;
    font-size: clamp(13px, 1.4vw, 14px);
}

.icon {
    stroke: var(--dropdown-icon)
}

.right-side-tables {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    flex-basis: 250px;
    flex-grow: 2;

}

.right-side-tables>div {
    flex-grow: 1;
}

.right-table>.table-header {
    color: var(--P2P-right-table-header);
    font-size: clamp(13px, 1.4vw, 14px);
}

table {
    width: 100%;
}

td:last-child {
    text-align: left;
}

td:first-child {
    text-align: right;
}

td:nth-child(2) {
    text-align: center;
}
.value {
    color:var(--P2P-headbar-value);

}

.green {
    color: rgba(11, 200, 16, 1);
}

.red {
    color: rgba(245, 43, 43, 1);;
}

.table-row {
    color: var(--P2P-table-row-color);
    font-size: clamp(12px, 1.3vw, 13px);
}

.table-section {
    background: var(--dashboard-section-bg);
    padding: 16px;
    border-radius: 15px;
    flex: 1;
    flex-basis: 250px;
    min-width: 230px;

}
</style>
