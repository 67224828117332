<template>
<div class="markets" >
    <div class="switch-comp">
        <SwitchComponent @choose="(choice)=>{toman_tether=choice}" :items="[{label:'تومان',value:'TOMAN'},{label:'تتر',value:'TETHER'}]" />
    </div>
    <table>
        <tr class="head">
            <!-- <ToggleComp  @choose="(choice)=>{toman_tether=choice}" :items="[{label:'تومان',value:'TOMAN'},{label:'تتر',value:'TETHER'}]"/> -->
            <td class="frst-head" align="right"> ارزها </td>
            <td>قیمت جهانی</td>
            <td class="change-head">تغییرات </td>
            <td class="resp-3">قیمت خرید</td>
            <td class="resp-2">قیمت فروش </td>
            <td class="resp-1 th-chart">نمودار</td>
            <td class="resp-1">عملیات</td>
        </tr>
        <tr class="row" v-for="(crypto,index) in filteredCryptos.slice(0,8)" :key="index" @click.prevent="$router.push('Panel/P2P/TOMAN/'+$coin2Snake[crypto.relatedCoin])">
            <td>
                <div class="title">
                    <img class="icon resp-1" :src="require('../../../../public/coins/'+crypto.relatedCoin+'.png')" />
                    {{$coinLabel[crypto.relatedCoin]}}
                </div>
            </td>
            <td class="price">{{$toLocal(crypto.lastPrice,toman_tether=='TOMAN'?0:2)}}</td>
            <td>
                <span class="change" :class="{red:crypto.last24HourChange<0,green:crypto.last24HourChange>0}">
                        <span v-if="crypto.last24HourChange>0">+</span>
                        <span v-if="crypto.last24HourChange<0">-</span>
                        {{Math.abs(crypto.last24HourChange).toFixed(2)}} %
                </span>
            </td>
            <td class="resp-3 price"><span>{{$toLocal(crypto.sellPrice,toman_tether=='TOMAN'?0:2)}}</span></td>
            <td class="resp-2 price"><span>{{$toLocal(crypto.buyPrice,toman_tether=='TOMAN'?0:2)}}</span></td>
            <td class="resp-1 ">
                <TrendChart :trend='crypto.lastWeekData' />
            </td>
            <td class="resp-1">
                <a class="action-buy" :href="'Panel/P2P/TOMAN/'+crypto.symbol" @click.prevent="$router.push('Panel/P2P/TOMAN/'+$coin2Snake[crypto.relatedCoin])">خرید</a>
                <a class="action-sell" :href="'Panel/P2P/TOMAN/'+crypto.symbol" @click.prevent="$router.push('Panel/P2P/TOMAN/'+$coin2Snake[crypto.relatedCoin])"> فروش </a>
            </td>
            <!-- <td class="resp-1 "><a class="action-sell" :href="'Panel/P2P/TOMAN/'+crypto.symbol" @click.prevent="$router.push('Panel/P2P/TOMAN/'+$coin2Snake[crypto.relatedCoin])"> فروش </a></td> -->
        </tr>
    </table>
    <!-- <div class="link-btn-container">
        <button class="link-btn">مشاهده همه</button>
    </div> -->
</div>
</template>

<script>
import SwitchComponent from '../../tools/SwitchComponent.vue'
import TrendChart from '../../tools/TrendChart.vue'
import {
 
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'Markets',
    components: {
        SwitchComponent,
        TrendChart
    },
    data() {
        return {
            cryptos: [],
            cryptoName: "",
            toman_tether: "TOMAN",
            loading: false,
            alive: false,
        }
    },
   
        computed:{
               filteredCryptos() {
            return this.cryptos.filter(item => {
                if (item.marketType.includes(this.toman_tether))
                    return item
            })

        },

        },
    methods: {

        async getMarkets() {
            let res = await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: true
                }
            }).then(response => res = response.content)
            if (res) {
                this.cryptos = res
            }
        },
       
    },
    mounted() {
        
        if (this.state.loop) {
            this.loop1 = new Loop(this.getMarkets, 5000, 'exchangeOrder')
            this.loop1.start()
        } else {
            this.getMarkets()
        }

    },
    beforeDestroy() {
        this.loop1.stop()
    }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.table-header {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    width: 100%;
}

.change {
    display: flex;
    justify-content: flex-end;
    direction: ltr;
    color: var(--publicpage-markets-change-action)
}

.link-btn {
    padding: 8px 32px;
    box-shadow: -8px -8px 16px rgba(38, 32, 111, 0.1), 8px 8px 16px rgba(2, 0, 23, 0.1), inset -8px -8px 16px rgba(48, 79, 221, 0.2), inset 8px 8px 16px rgba(48, 79, 221, 0.4);
    border-radius: 50px;
    margin-top: 16px;
    font-size: 14px;
    background: var(--publicpage-markets-link-btn-bg);
    color: var(--publicpage-markets-link-btn-text);
    border: 0.3px solid var(--publicpage-markets-link-btn-border);

}

.link-btn-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.action-buy , .action-sell {
    color: var(--publicpage-markets-change-action);
    font-weight: 300;
    font-size: clamp(12px , 1vw , 14px);
    padding: 4px 8px;
    border-radius: 50px;
}
.action-buy {
     border: 1px solid rgba(11, 200, 16, 1);
     color: rgba(11, 200, 16, 1);

    display: inline-block;
    width: 60px;

}
.action-sell {
    border: 1px solid rgba(245, 43, 43, 1);
    color: rgba(245, 43, 43, 1);

    display: inline-block;
    width: 60px;

}
.green{
    color:rgba(11, 200, 16, 1);
}
.red{
    color:rgba(245, 43, 43, 1);

}
.chart-container {
    display: flex;
    /* width: 100px; */

    align-items: center;
}

.markets {
    /* padding-top: clamp(80px, 12vw, 130px); */
    background: var(--background);
    /* padding: clamp(12px, 1.3vw, 16px) clamp(30px, 3.5vw, 48px); */
    border-radius: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
        box-sizing: border-box;
    padding: 20px;
}

.chart {
    vertical-align: inherit;
    max-height: 60px;
}

.switch-comp {
        max-width: 320px;
    margin-right: 0 auto;
    margin: 20px auto;
}

.title {
    color: var(--publicpage-markets-title);
    font-size: 14px;
    /* display: flex; */
    display: flex;
    align-items: center;
    font-weight: bold;
}

.title-basecoin {
    font-size: 12px;
    font-weight: 300;
}

.markets-link {
    text-align: center;
    margin-top: 16px;
}

.icon {
    margin-left: 8px;
    width: 30px;
}

.head {
    background: transparent;
    color: var(--publicpage-markets-header);
    height: 41px;
    ;
    font-size: clamp(13px, 1.3vw, 14px);
    font-weight: 400px !important;
}

.row {
    height: 64px;
    font-size: clamp(12px, 1.3vw, 14px);
}

td {
    font-weight: 400px !important;
}

.price {
    color: var(--publicpage-markets-price);
}

table {
    margin-top: 10px;
    border-collapse: collapse;
    width: 100%;
}
tr td:last-child {
    text-align: center;
}
td a {
    margin-left: 10px;
}
.th-chart {
    text-align: center;
}
@media(max-width:900px) {
    .resp-1 {
        display: none;
    }
}

@media(max-width:550px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:400px) {
    .resp-3 {
        display: none;
    }
    .change-head , .change {
        text-align: left !important;
        justify-content: flex-start;
    }
}
</style>
<style >
.vtc {
    margin: 0 auto;
}
</style>
