<template>
<!-- <div> -->
  <!-- <div v-if="errorText" class="error" >*{{errorText}}</div> -->
  <div class="label-input" :class="{disabled:disabled}">
      <label :class="{disabled:disabled}" >{{label}}</label>
      <div class="input-container" >
          <input :class="{disabled:disabled}" :maxLength="maxLength"  :type="(type=='password'&& !passShow)?'password':'text'"  
          @input="inputHandler()" v-model="temp" :disabled="disabled" :placeholder="placeholder"/>
          <img  v-if="type=='password' && passShow" src="../../assets/icons/showpass.svg"  @click="passShow=!passShow"  class="input-icon"/>
          <img  v-if="type=='password' && !passShow"  src="../../assets/icons/hidepass.svg"  @click="passShow=!passShow"  class="input-icon"/>
          <img  v-if="type=='securityCode'" class="input-icon" src="../../assets/icons/reloadcode.svg"/>
        
      </div>
  </div>

<!-- </div> -->

    
</template>

<script>
export default {
  name: 'LabelInput',
  
  props:['label','disabled','value','errorText','type','placeholder','maxLength'],
  
  data(){
    return{
      passShow:false,
      temp:this.value
    }
  },
  watch:{
       'value'(){
         this.temp=this.value
       }
  },
  methods:{
     inputHandler(){
      if(this.type=="number")
        {this.temp=this.$toLocal(this.temp)}
      else if(this.type=='nochar')  
        this.temp=this.temp.replace(/[^\d.]|\.\./ig,'')
      this.$emit('input',this.temp)
    },





    
  }
  ,mounted(){
    this.temp=this.value
  }
}
</script>
<style scoped>
.label-input{
    margin-top:6px;
    /* width: 100%; */
    border:1px solid var(--label-input-border) ;
    border-radius: 50px;
    display: flex;
    background: var(--label-input-bg);
    max-height: 42px;
    flex-grow: 1;
    text-align: right;
    flex: 1;
}
input{
    height: 100%;
     flex-grow: 1;
     size: 1;
    width: 60px;
    background: var(--label-input-bg);
    color: var(--label-input-text);
    border-top-left-radius:50px;
    border-bottom-left-radius:50px;

}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:  var(--label-input-text);
  opacity: .5; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:  var(--label-input-text);
  opacity: .5;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color:  var(--label-input-text);
  opacity: .5;
}

.error{
  color: red;
  font-size: clamp(10px,1.2vw,14px);
  display: flex;
  line-height: 0px;
  align-items: center;
}
.input-container{
  display: flex;
  width: 100%;
  height:40px;
  align-items: center;
  position: relative;
}
label{
     border-radius: 0px 50px 50px 0px;
    font-size: clamp(12px,1.3vw,13px);
     background: var(--label-input-bg);
    color: var(--label-input-text);

    display: flex;
    padding:0px 10px;
    align-items: center;
  flex-grow: 1;
  white-space: nowrap;
 

    
}

.input-icon{
   position: absolute;
   left:10px;
   cursor: pointer;
  
}

.disabled{
  background: var(--label-input-disabled);
}
</style>
